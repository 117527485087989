import { FC, PropsWithChildren, useEffect, useReducer, useState } from 'react'

import axios from 'axios'
import { useAstaMessage } from 'contexts/astaMessage/useAstaMessage'
import { IUser } from 'interfaces/user'
import { signIn as NaSignIn, signOut, useSession } from 'next-auth/react'

import { AuthContext, authReducer } from './'

export interface AuthState {
	isLoggedIn: boolean
	user?: IUser
}

const AUTH_INITIAL_STATE: AuthState = {
	isLoggedIn: false,
	user: undefined
}

export type AuthProviderProps = {
	repositoryUrl: string
	companionUrl: string
	cognito: {
		clientId: string
		domain: string
		redirectUrl: string
	}
}

// Note: node-config-ts isn't available in error pages, so service urls strings will be
// undefined for 404s/500s/etc.
export const AuthProvider: FC<PropsWithChildren<AuthProviderProps>> = ({ repositoryUrl, children, cognito }) => {
	const [state, dispatch] = useReducer(authReducer, AUTH_INITIAL_STATE)
	const { data, status } = useSession()
	const { addMessage } = useAstaMessage()
	const [isUserNotified, setIsUserNotified] = useState(false)

	useEffect(() => {
		if (status === 'authenticated') {
			const userStatus = (data?.user as IUser)?.status ?? 'inactive'

			if (userStatus === 'active') {
				dispatch({
					type: '[Auth] - Login',
					payload: data?.user as IUser
				})
			}

			if (userStatus === 'waitlisted' && !isUserNotified) {
				addMessage({
					variant: 'success',
					title: "You're on our waitlist! ",
					content:
						"Your email address has been verified. We'll notify you as soon as a ASTA becomes available. Thank you for your interest!"
				})
				setIsUserNotified(true)
			}
		}
	}, [status, data, isUserNotified, setIsUserNotified])

	const logIn = () => {
		NaSignIn('cognito')
	}

	const signUp = () => {
		const url = `https://${cognito.domain}/signup?client_id=${cognito.clientId}&scope=openid&response_type=code&redirect_uri=${cognito.redirectUrl}/api/auth/callback/cognito`
		window.location.href = url
	}

	const logout = async () => {
		await axios.post(`${repositoryUrl}/api/v2/auth/logout`).then(async () => {
			await signOut({ callbackUrl: `/` })
			dispatch({ type: '[Auth] - Logout' })
		})
	}

	return (
		<AuthContext.Provider
			value={{
				...state,

				// Methods
				logIn,
				logout,
				signUp
			}}
		>
			{children}
		</AuthContext.Provider>
	)
}
